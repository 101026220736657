import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M3202 7660 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3264 7655 c-26 -26 -34 -88 -13 -101 15 -9 2 -69 -27 -123 -13 -25
-24 -56 -24 -68 0 -12 -4 -24 -8 -27 -5 -3 -14 -30 -21 -61 -7 -30 -17 -55
-23 -55 -5 0 -8 -6 -5 -13 3 -8 1 -20 -4 -28 -7 -11 -9 -10 -9 6 -1 19 -1 19
-11 1 -9 -14 -8 -20 2 -27 7 -4 11 -11 7 -14 -3 -3 -17 9 -31 27 -15 18 -33
41 -42 51 -8 9 -15 24 -15 32 0 8 -6 15 -14 15 -8 0 -24 14 -36 30 -12 17 -29
30 -39 30 -9 0 -22 5 -27 10 -6 6 -18 8 -28 4 -13 -5 -16 -13 -11 -31 4 -19 1
-26 -15 -30 -26 -7 -26 -22 1 -31 14 -6 28 -29 44 -75 l24 -67 -28 0 c-28 0
-121 62 -121 80 0 6 -5 10 -11 10 -5 0 -7 -4 -4 -10 3 -5 1 -10 -4 -10 -6 0
-11 10 -11 23 0 31 -18 47 -54 47 -42 0 -58 -12 -62 -45 -4 -44 12 -77 36 -70
11 4 20 2 20 -4 0 -6 -6 -11 -12 -11 -7 0 0 -11 16 -23 17 -14 26 -29 22 -35
-4 -7 -2 -12 3 -12 6 0 18 -16 26 -36 18 -43 19 -58 5 -49 -15 9 -42 -15 -36
-32 3 -8 -2 -13 -12 -13 -15 0 -15 -2 -2 -10 23 -15 60 -12 60 5 0 11 9 13 38
9 20 -3 82 -7 137 -8 l100 -3 -100 7 -100 6 108 2 c70 1 106 -2 102 -8 -3 -5
3 -10 15 -10 12 0 18 4 15 10 -4 7 114 10 361 10 416 -1 393 3 409 -83 5 -23
9 -44 10 -47 34 -107 45 -146 45 -161 0 -10 4 -19 9 -19 5 0 17 -29 26 -64 19
-71 22 -73 155 -96 81 -14 140 -31 140 -40 0 -4 12 -5 28 -3 17 3 23 1 15 -4
-8 -6 1 -9 25 -9 34 0 54 -4 172 -34 25 -7 55 -13 68 -13 12 -1 22 -9 22 -17
0 -9 6 -7 16 8 30 43 22 48 -156 84 -30 6 -98 21 -150 35 -52 13 -135 32 -184
43 -49 10 -93 23 -98 28 -5 5 -10 30 -11 55 -1 25 -5 40 -9 34 -4 -7 -8 -9 -8
-5 0 12 -51 192 -60 213 -24 57 -32 97 -20 105 8 5 5 9 -9 13 -22 5 -20 27 5
57 7 8 16 27 19 43 4 15 13 27 21 27 8 0 14 8 14 18 0 22 36 47 43 29 3 -7 5
-3 6 8 0 11 2 33 5 49 5 34 -33 80 -58 70 -10 -4 -16 -1 -16 7 0 8 -10 -1 -22
-18 -13 -18 -32 -35 -43 -39 -19 -6 -19 -6 -2 -11 16 -4 13 -9 -17 -29 -42
-29 -55 -31 -32 -5 18 20 14 41 -7 41 -8 0 -7 -3 2 -9 11 -7 9 -13 -8 -30 -12
-12 -26 -19 -31 -16 -6 4 -10 -2 -10 -12 0 -10 -13 -27 -28 -37 l-27 -20 18
21 c14 16 15 22 5 25 -10 4 -8 17 5 64 28 91 30 102 23 130 -6 23 -10 25 -39
19 -18 -4 -40 -14 -50 -23 -16 -14 -19 -14 -27 -2 -7 12 -12 8 -21 -17 -6 -19
-18 -33 -27 -33 -12 0 -13 -3 -5 -11 8 -8 6 -20 -9 -45 -22 -37 -31 -44 -21
-16 6 15 5 15 -10 3 -11 -8 -15 -21 -12 -32 4 -11 2 -19 -4 -19 -6 0 -11 15
-11 33 0 18 -7 42 -15 53 -8 10 -20 37 -26 59 -6 22 -18 56 -26 75 -17 39 -34
78 -56 131 -9 21 -12 40 -7 45 5 5 12 27 15 49 9 54 -16 86 -69 91 -30 2 -44
-3 -62 -21z m86 -30 c26 -32 -13 -81 -47 -59 -35 22 -23 74 17 74 10 0 23 -7
30 -15z m4 -157 c73 -165 169 -489 150 -507 -8 -9 -361 -7 -370 1 -11 12 41
205 98 363 56 156 74 195 89 195 5 0 21 -24 33 -52z m-258 -361 c38 -53 21
-58 -26 -7 -54 59 -74 51 -54 -22 7 -29 14 -54 14 -55 0 -2 -6 -3 -14 -3 -15
0 -25 24 -51 134 -9 38 -21 79 -25 92 -18 45 7 34 67 -33 35 -37 75 -85 89
-106z m527 155 c-21 -22 -26 -21 -17 2 3 9 12 16 20 16 11 0 10 -4 -3 -18z
m84 1 c-6 -16 -32 -111 -58 -205 -5 -22 -16 -38 -24 -38 -18 0 -19 5 -1 64 13
42 13 48 -1 53 -10 4 -28 -10 -53 -38 -21 -24 -41 -41 -45 -37 -9 8 49 87 120
166 48 53 72 66 62 35z m-984 -59 c9 -10 -4 -34 -18 -34 -17 0 -28 17 -20 30
7 11 28 13 38 4z m1230 0 c9 -9 -4 -34 -18 -34 -14 0 -27 25 -18 34 4 3 12 6
18 6 6 0 14 -3 18 -6z m-1128 -99 c28 -16 87 -55 132 -85 77 -52 80 -55 55
-61 -35 -8 -141 -10 -167 -3 -14 3 -34 34 -62 94 -39 85 -44 108 -19 93 6 -4
34 -21 61 -38z m1075 39 c0 -3 -19 -45 -42 -94 -46 -99 -48 -100 -162 -100
-109 0 -104 12 49 111 134 87 155 98 155 83z m-330 -148 c-5 -15 -14 -24 -20
-21 -15 9 -12 38 8 65 16 24 17 24 20 4 2 -11 -2 -33 -8 -48z m-478 30 c12
-28 3 -65 -12 -50 -5 5 -13 24 -16 42 -8 39 11 44 28 8z"/>
<path d="M3205 7590 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3230 7530 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3401 7514 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3215 7487 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17 -12
0z"/>
<path d="M3421 7454 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2845 7170 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2900 7130 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1548 7064 c-5 -4 -8 -16 -8 -26 0 -31 61 -50 146 -46 41 2 75 -1 77
-6 2 -6 32 -11 66 -12 35 0 66 -5 70 -10 3 -5 20 -10 36 -11 34 -1 209 -37
270 -55 22 -7 47 -12 55 -13 8 0 20 -3 26 -7 6 -4 37 -12 70 -17 32 -6 91 -20
129 -31 39 -10 80 -20 93 -20 12 0 22 -4 22 -10 0 -5 19 -9 43 -7 35 2 42 6
42 22 0 17 -14 23 -100 42 -55 11 -100 22 -100 23 0 1 -9 3 -20 5 -127 23
-209 46 -195 55 7 5 2 9 -14 12 -15 3 -23 1 -19 -5 3 -5 -6 -7 -23 -3 -16 4
-42 9 -59 12 -16 3 -34 9 -40 13 -5 5 -37 11 -70 15 -33 4 -91 16 -130 27 -38
10 -87 21 -108 24 -20 2 -46 9 -58 15 -25 14 -191 25 -201 14z m189 -60 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m68 -14 c13 -6 15 -9 5 -9
-8 0 -22 4 -30 9 -18 12 -2 12 25 0z"/>
<path d="M1960 7016 c0 -2 9 -6 20 -9 11 -3 20 -1 20 4 0 5 -9 9 -20 9 -11 0
-20 -2 -20 -4z"/>
<path d="M1848 6953 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2328 6953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4467 6953 c-4 -3 -7 -17 -7 -30 0 -18 5 -23 25 -23 16 0 25 6 25 15
0 8 9 15 21 15 11 0 17 -4 14 -10 -4 -7 133 -9 407 -8 l413 3 -400 6 -400 6
485 -3 c404 -2 497 -5 555 -19 39 -9 79 -16 90 -16 10 0 24 -4 30 -8 5 -4 31
-14 57 -21 35 -9 45 -16 41 -28 -5 -11 -2 -14 10 -9 9 3 19 2 22 -3 3 -5 13
-6 22 -3 12 5 14 3 8 -7 -6 -10 -3 -12 13 -8 12 4 22 2 22 -2 0 -5 9 -12 21
-15 30 -10 109 -65 109 -76 0 -5 6 -9 13 -9 8 0 24 -11 37 -25 24 -26 40 -25
40 2 0 18 -47 83 -60 83 -8 0 -131 78 -156 99 -7 6 -15 7 -18 4 -3 -4 -25 3
-48 14 -60 30 -130 50 -233 68 -71 12 -186 15 -620 15 -292 0 -535 -3 -538 -7z"/>
<path d="M6176 6953 c-3 -3 -6 -14 -6 -24 0 -9 -6 -19 -12 -22 -7 -2 -1 -8 14
-12 29 -7 52 1 43 16 -4 5 311 9 814 9 l821 0 -1 -322 c0 -178 -3 -317 -6
-311 -3 10 -9 9 -21 -3 -15 -15 -14 -17 2 -29 12 -9 27 -11 49 -4 18 5 33 9
34 9 1 0 0 7 -3 15 -4 9 0 15 7 15 11 0 11 2 2 8 -10 6 -12 82 -10 330 2 314
2 321 -18 326 -29 8 -1701 7 -1709 -1z"/>
<path d="M2458 6943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1695 6916 c28 -13 106 -38 175 -55 69 -18 215 -57 325 -87 110 -30
232 -63 270 -74 39 -10 108 -29 155 -41 56 -14 92 -30 105 -44 16 -18 62 -33
215 -70 107 -25 245 -53 305 -60 121 -16 172 -28 225 -55 73 -36 270 -79 365
-79 109 0 215 -27 295 -76 40 -24 93 -42 195 -65 77 -18 212 -50 300 -72 88
-22 194 -45 235 -50 41 -5 113 -23 160 -40 119 -42 1347 -351 1860 -468 118
-27 379 -91 525 -129 192 -50 333 -82 377 -86 24 -3 200 -41 392 -85 210 -49
365 -80 389 -78 35 3 23 8 -89 40 -71 21 -199 55 -285 77 -85 21 -193 50 -240
64 -46 14 -103 31 -126 37 l-42 11 -3 77 -3 77 -219 3 c-188 2 -221 0 -236
-13 -16 -15 -24 -15 -81 0 -167 42 -325 88 -337 98 -8 6 -12 27 -10 56 l3 46
250 5 250 5 0 170 0 170 -250 5 -250 5 0 180 0 180 210 0 210 0 3 -118 3 -117
227 2 227 3 0 290 0 290 -800 0 -800 0 0 -170 0 -170 80 -5 80 -5 3 -329 c1
-182 -2 -334 -6 -338 -5 -5 -16 -6 -25 -2 -14 5 -17 20 -17 83 0 443 -211 750
-612 890 -152 53 -255 61 -758 61 l-450 0 0 -175 0 -174 78 -3 77 -3 3 -122
c2 -96 0 -123 -10 -123 -13 0 -285 60 -518 115 -63 15 -137 32 -165 38 l-50
12 -37 115 c-20 63 -51 161 -69 218 l-32 102 -510 -2 -510 -3 2 -72 c1 -41 -2
-74 -8 -78 -12 -7 -237 39 -421 85 -63 16 -188 45 -278 64 -90 20 -208 47
-262 61 -119 30 -130 30 -65 1z m3678 -421 c127 -33 221 -133 268 -287 21 -69
37 -208 24 -208 -5 0 -74 18 -154 40 -80 22 -186 50 -236 63 l-90 24 -3 179
c-1 98 0 184 2 191 7 18 117 17 189 -2z"/>
<path d="M5252 6463 c-6 -2 -9 -9 -6 -15 4 -5 6 -60 5 -121 -1 -62 3 -119 9
-127 10 -16 13 -17 56 -19 14 0 23 -5 20 -10 -3 -5 1 -12 10 -15 10 -4 13 -2
8 6 -5 8 0 9 17 4 92 -29 127 -39 149 -41 44 -4 46 -7 10 -15 -27 -6 -22 -7
25 -5 43 2 61 7 63 17 4 20 -19 37 -52 40 -15 1 -32 10 -37 20 -5 10 -15 18
-22 18 -7 0 -6 -4 3 -10 22 -14 -1 -12 -67 6 -32 9 -77 20 -100 26 l-42 9 -3
117 c-3 120 -8 131 -46 115z"/>
<path d="M5457 6119 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M2325 6930 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23 -10z"/>
<path d="M2380 6920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3258 6913 c34 -2 90 -2 125 0 34 2 6 3 -63 3 -69 0 -97 -1 -62 -3z"/>
<path d="M3468 6913 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M3618 6913 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M3728 6913 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2078 6903 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3911 6894 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4411 6894 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7815 6900 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5568 6893 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5625 6880 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M5938 6863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5750 6843 c0 -5 15 -9 33 -10 26 -1 28 0 12 7 -26 11 -45 12 -45 3z"/>
<path d="M5985 6840 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1718 6833 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2390 6830 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M2438 6813 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1425 6783 c-47 -10 -48 -13 -21 -38 14 -13 26 -29 26 -34 0 -16 68
-47 140 -64 36 -8 103 -27 150 -42 47 -15 148 -42 225 -61 77 -19 154 -39 170
-44 40 -13 215 -61 345 -95 190 -49 240 -65 240 -77 0 -7 -6 -30 -14 -53 -8
-22 -22 -69 -32 -105 -9 -36 -20 -69 -23 -75 -3 -5 -6 -14 -7 -20 -4 -24 -74
-249 -91 -295 -4 -8 -7 -17 -8 -20 -1 -3 -7 -25 -14 -50 -68 -231 -104 -347
-110 -360 -7 -12 -24 -14 -82 -12 -53 3 -82 -1 -104 -12 -17 -9 -23 -16 -14
-16 21 0 30 -22 18 -41 -9 -14 -11 -343 -2 -367 4 -10 98 -12 462 -11 286 1
461 5 469 11 9 8 12 64 12 218 0 180 -2 209 -16 214 -9 3 -48 6 -88 6 l-73 0
1 32 c2 54 23 59 251 56 110 -1 206 -4 212 -6 13 -4 36 -72 27 -81 -3 -3 -51
-6 -107 -6 l-102 0 0 -220 0 -220 1085 0 1085 0 110 23 c187 40 312 100 453
218 42 35 80 64 84 64 5 0 8 -67 8 -149 0 -107 3 -151 13 -158 17 -13 1552
-16 1596 -3 20 5 40 6 50 1 10 -6 24 -5 34 0 14 8 16 19 11 56 -3 26 -7 50
-10 54 -2 5 -2 19 2 33 5 22 10 24 37 19 44 -8 170 -38 242 -57 33 -9 80 -21
105 -26 153 -32 239 -51 345 -78 66 -17 138 -33 160 -37 22 -3 103 -21 180
-40 226 -55 498 -111 576 -120 53 -6 57 -5 62 17 16 62 -12 85 -133 113 -41 9
-95 24 -120 33 -25 9 -61 18 -80 20 -19 1 -37 6 -40 10 -3 5 -45 18 -95 29
-145 34 -179 44 -190 52 -5 5 -30 11 -55 14 -24 3 -46 11 -48 16 -2 6 -8 9
-12 6 -11 -7 -141 24 -154 36 -6 5 -20 9 -31 9 -12 0 -25 3 -29 8 -4 4 -17 7
-29 8 -12 1 -58 11 -102 24 -135 38 -213 58 -398 104 -98 24 -188 51 -200 59
-12 8 -39 17 -60 21 -24 4 -35 11 -31 18 4 6 3 8 -3 5 -12 -7 -244 53 -239 62
2 3 -8 6 -23 7 -48 2 -82 14 -75 26 4 6 -3 4 -17 -5 -13 -9 -31 -17 -41 -17
-35 0 -383 99 -393 112 -5 7 -63 26 -127 42 -65 17 -118 33 -118 38 0 4 -7 5
-15 2 -8 -4 -15 -1 -15 5 0 6 -17 11 -39 11 -22 0 -68 9 -103 20 -34 11 -77
20 -95 20 -18 0 -38 6 -45 13 -7 8 -22 12 -33 10 -17 -4 -18 -3 -5 7 12 9 11
10 -7 5 -26 -7 -66 2 -59 14 3 4 -8 5 -25 3 -16 -2 -35 0 -42 6 -6 6 -34 13
-62 17 -27 3 -79 15 -115 25 -36 10 -92 24 -125 31 -33 7 -64 16 -70 21 -5 4
-23 8 -40 8 -41 0 -107 19 -100 29 2 5 -9 11 -25 13 -17 3 -30 1 -30 -4 0 -5
-9 -4 -19 2 -11 5 -31 10 -45 10 -14 0 -27 4 -30 9 -3 5 -27 12 -53 15 -27 4
-68 13 -93 21 -100 31 -196 55 -220 55 -20 0 -22 2 -10 11 12 9 12 10 -4 5
-47 -16 -396 68 -396 95 0 5 -7 6 -15 3 -8 -4 -15 -2 -15 2 0 5 -21 10 -48 12
-26 1 -74 10 -107 18 -33 9 -82 20 -110 25 -27 4 -79 17 -115 28 -36 12 -75
21 -87 21 -13 0 -23 5 -23 11 0 6 -10 9 -22 8 -29 -3 -68 9 -68 22 0 5 -5 9
-11 9 -5 0 -7 -5 -4 -10 18 -29 -69 -15 -160 25 -17 8 -77 23 -135 35 -57 12
-108 26 -112 32 -3 7 -12 13 -20 15 -34 8 -181 35 -258 48 -132 22 -352 84
-375 106 -5 5 -26 9 -46 9 -20 0 -59 6 -85 14 -123 37 -188 47 -221 34 -26
-10 -38 -9 -70 4 -21 9 -69 24 -108 33 -38 9 -79 21 -90 25 -11 4 -51 13 -90
19 -51 9 -75 18 -88 34 -15 19 -16 19 -10 3 5 -17 3 -18 -18 -12 -13 4 -44 11
-69 16 -77 15 -154 37 -142 41 7 2 -7 5 -31 6 -23 1 -89 13 -147 28 -58 14
-136 32 -175 40 -38 8 -99 21 -135 29 -36 9 -67 15 -70 14 -3 0 -14 -2 -25 -5z
m1830 -703 c9 -36 20 -74 24 -85 25 -63 50 -149 46 -154 -6 -6 -53 -6 -138 -1
-53 3 -57 5 -51 24 4 12 11 35 15 51 4 17 15 55 23 85 9 30 16 67 16 81 0 15
7 32 15 39 8 7 15 20 15 28 0 33 20 -5 35 -68z m832 -111 c29 -11 165 -49 268
-74 33 -8 89 -23 125 -33 l65 -19 3 -250 c2 -240 2 -251 -17 -256 -27 -7 -71
3 -71 16 0 7 -4 7 -12 -1 -8 -8 -48 -12 -114 -12 l-101 0 -42 133 c-23 72 -54
170 -68 217 -14 47 -32 100 -40 118 -7 18 -9 31 -3 27 6 -3 -3 17 -20 45 -32
55 -39 100 -16 100 8 0 27 -5 43 -11z m1293 -349 c47 -12 102 -26 123 -31 l37
-10 -21 -42 c-65 -127 -119 -169 -239 -186 -41 -5 -78 -7 -82 -3 -5 6 -13 251
-9 304 1 18 -8 19 191 -32z m864 -206 c11 -4 16 -19 16 -44 0 -31 -4 -38 -23
-42 -12 -2 -28 -3 -35 0 -18 6 -15 52 5 74 18 21 16 20 37 12z"/>
<path d="M4438 5763 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5378 5443 c10 -4 9 -9 -4 -24 -21 -23 -12 -26 10 -3 17 16 13 35 -7
33 -8 0 -8 -2 1 -6z"/>
<path d="M5888 6783 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3864 6708 c3 -18 11 -37 18 -43 8 -7 9 -5 4 8 -3 10 -6 30 -6 43 0
13 -5 24 -11 24 -8 0 -9 -11 -5 -32z"/>
<path d="M1978 6713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2058 6703 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2240 6690 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M6083 6658 c3 -9 1 -19 -6 -22 -7 -2 -4 -5 6 -5 20 -1 21 7 6 29 -9
13 -10 13 -6 -2z"/>
<path d="M2365 6640 c3 -6 13 -10 20 -10 8 0 12 -6 9 -14 -3 -8 4 -16 18 -19
13 -3 61 -19 108 -36 73 -25 87 -28 96 -15 6 8 25 14 42 14 39 0 34 26 -6 33
-44 8 -166 38 -188 47 -11 4 -28 4 -36 -1 -10 -6 -27 -5 -43 1 -17 7 -24 7
-20 0z"/>
<path d="M3910 6560 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4030 6540 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M4138 6543 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6276 6462 c-3 -6 -1 -23 5 -39 l11 -28 11 23 c8 18 7 26 -5 38 -10
10 -18 12 -22 6z"/>
<path d="M1928 6453 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4511 6456 c2 -2 20 -6 39 -10 25 -4 31 -3 20 4 -14 9 -69 15 -59 6z"/>
<path d="M6970 6332 c0 -128 0 -129 25 -140 14 -7 31 -12 38 -11 7 0 3 4 -8 9
-12 5 68 9 200 9 145 1 215 -3 205 -9 -20 -13 -4 -13 21 0 10 6 19 7 19 3 0
-4 1 -93 1 -197 1 -187 1 -190 -21 -198 -33 -12 -7 -40 34 -36 l31 3 3 224 c1
124 -1 228 -5 232 -5 4 -117 11 -250 15 l-243 7 0 88 c0 52 4 89 10 89 5 0 2
9 -7 20 -10 11 -25 20 -35 20 -16 0 -18 -12 -18 -128z"/>
<path d="M4058 6443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4180 6420 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z"/>
<path d="M3388 6373 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2278 6363 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4468 6353 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3758 6293 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M3928 6263 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2571 6234 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7293 6183 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M7798 6163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2568 6153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4408 6123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5005 5980 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M5040 5970 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M7000 5730 c0 -5 7 -10 15 -10 8 0 14 -5 13 -11 -2 -6 3 -14 11 -17
8 -3 11 0 7 7 -9 14 18 15 42 2 9 -6 38 -13 65 -16 26 -3 47 -10 47 -14 0 -15
104 -24 287 -25 116 -1 183 3 183 9 0 6 4 4 9 -4 7 -11 22 -12 63 -7 113 14
103 19 105 -54 0 -36 1 -70 2 -77 1 -6 16 -17 35 -23 19 -6 37 -16 40 -23 5
-9 7 -9 12 0 3 7 12 9 20 6 13 -5 13 -7 2 -15 -9 -6 -3 -8 19 -6 18 3 30 1 27
-4 -3 -5 7 -6 23 -3 20 5 24 4 14 -3 -10 -7 2 -12 45 -17 54 -7 103 -18 191
-46 18 -6 39 -7 45 -3 7 4 5 -2 -5 -13 -9 -11 -14 -23 -10 -26 12 -12 60 -7
72 7 16 19 24 86 10 86 -5 0 -7 -4 -4 -10 10 -16 -24 -11 -52 8 -18 11 -24 12
-19 3 6 -10 4 -12 -10 -8 -11 3 -53 15 -94 27 -41 11 -84 25 -95 29 -11 5 -45
14 -75 21 -30 6 -74 19 -97 27 -39 14 -43 19 -43 49 0 21 5 34 13 34 9 0 8 3
-2 9 -9 6 -12 20 -9 40 3 21 0 32 -11 36 -8 3 -141 5 -295 5 -368 -1 -344 -2
-326 11 13 9 12 10 -3 5 -10 -3 -46 1 -80 9 -71 17 -187 20 -187 5z"/>
<path d="M6945 5720 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6828 5523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8068 5513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6990 5480 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M7878 5463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7128 5453 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7155 5440 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M7908 5263 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8200 5200 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M8291 5176 c2 -2 22 -6 44 -9 27 -4 35 -3 25 3 -14 9 -79 15 -69 6z"/>
<path d="M5889 4999 c-23 -19 -23 -20 -2 -10 12 6 25 16 28 21 10 16 -1 12
-26 -11z"/>
<path d="M5825 4960 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7948 4933 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8008 4893 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6020 4824 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M2208 4823 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8320 4820 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4490 4804 c-25 -8 -56 -12 -70 -9 -19 4 -21 3 -10 -4 12 -8 11 -11
-5 -17 -24 -9 -128 -74 -135 -84 -3 -4 -27 -33 -55 -65 -27 -31 -50 -59 -50
-63 -1 -4 -5 -16 -11 -27 -6 -11 -19 -47 -29 -80 -10 -33 -22 -65 -26 -71 -5
-6 -6 -13 -3 -17 3 -3 8 -31 11 -63 5 -55 4 -58 -30 -93 -77 -77 -107 -114
-113 -137 -4 -13 -10 -27 -15 -30 -5 -3 -9 -14 -9 -25 0 -10 -4 -19 -10 -19
-6 0 -10 36 -10 89 0 100 -10 121 -61 121 -24 0 -29 4 -29 24 0 42 -22 50
-141 47 l-109 -3 0 76 c0 72 1 77 24 86 37 14 46 40 46 135 0 79 -2 88 -26
111 -19 20 -31 24 -51 19 -21 -5 -24 -3 -19 11 4 9 -1 27 -9 40 -15 24 -16 24
-215 24 -167 0 -201 -2 -214 -16 -17 -17 -19 -48 -9 -144 6 -57 9 -64 35 -74
l28 -10 0 -188 c0 -103 0 -191 0 -195 1 -5 -8 -9 -19 -11 -38 -6 -46 -30 -42
-126 3 -95 11 -116 42 -116 22 0 27 -35 7 -43 -8 -4 -7 -6 3 -6 9 -1 19 -10
22 -22 3 -11 15 -23 26 -26 17 -4 19 -8 10 -19 -7 -8 -7 -14 -1 -14 5 0 15 7
22 15 11 14 56 16 333 14 374 -1 347 -10 347 110 l0 75 29 -34 c28 -34 31 -35
109 -38 94 -5 130 4 317 78 75 29 202 85 210 92 22 19 27 6 5 -14 -14 -13 -29
-22 -35 -20 -5 1 -2 -4 8 -11 13 -11 17 -29 17 -85 0 -74 -3 -78 -47 -54 -13
7 -23 19 -23 27 0 8 -6 15 -14 15 -7 0 -21 12 -30 26 -13 18 -24 24 -44 22
-23 -3 -27 -7 -24 -31 l3 -29 -31 18 c-29 18 -31 18 -64 0 -32 -17 -33 -19
-18 -37 9 -10 24 -19 33 -19 9 0 28 -9 42 -20 14 -11 33 -20 42 -20 10 0 24
-8 31 -17 11 -16 31 -18 161 -18 l148 0 75 39 c41 21 84 43 95 49 11 6 40 35
65 64 25 30 55 66 67 80 13 14 20 29 17 34 -3 5 2 6 10 3 9 -3 16 -1 16 5 0 6
-4 11 -9 11 -5 0 -1 25 10 55 22 64 33 255 14 255 -21 0 -55 -32 -55 -51 0
-14 -6 -19 -17 -17 -29 5 -21 23 27 60 25 18 49 44 55 56 5 12 17 24 26 27 9
3 21 17 27 30 6 14 16 25 22 25 5 0 10 7 10 15 0 7 7 18 16 23 9 5 15 12 15
15 -3 20 0 27 10 27 6 0 8 -8 5 -17 -8 -26 16 -68 39 -68 19 0 20 -9 23 -197
l2 -198 -24 0 c-31 0 -41 -27 -37 -97 2 -43 0 -53 -13 -53 -9 0 -16 -5 -16
-11 0 -6 7 -9 15 -5 9 3 15 0 15 -9 0 -7 -8 -16 -17 -18 -15 -4 -16 -5 -2 -6
9 0 19 -10 22 -21 3 -14 14 -20 31 -20 22 0 26 -4 26 -31 0 -17 5 -39 10 -50
11 -19 23 -19 312 -19 165 1 307 5 317 10 9 4 41 20 73 34 63 28 103 72 123
138 l14 43 -3 -57 c-4 -59 2 -68 45 -68 16 0 19 -7 19 -40 0 -31 5 -43 25 -56
14 -9 32 -13 41 -10 9 4 206 6 438 5 232 -1 434 -1 451 0 46 1 55 23 55 131 0
84 -3 99 -21 119 -15 15 -29 21 -45 18 -19 -3 -24 0 -24 17 0 12 -6 30 -13 41
-8 11 -16 29 -20 40 -3 11 -15 45 -26 75 -12 30 -21 66 -21 80 0 14 -5 31 -11
37 -11 11 -72 203 -83 263 -11 56 -42 90 -83 90 -13 0 -22 11 -28 33 l-10 32
-241 3 c-185 2 -244 -1 -251 -10 -13 -16 -24 -210 -12 -202 5 3 6 -2 3 -10 -3
-9 -2 -16 4 -16 6 0 13 6 15 13 3 6 6 2 6 -9 1 -12 -8 -32 -18 -46 -11 -14
-20 -33 -20 -44 0 -24 -10 -73 -21 -99 -4 -11 -20 -64 -35 -118 -16 -54 -32
-100 -36 -103 -4 -2 -14 2 -21 9 -10 12 -15 11 -32 -5 -22 -20 -36 -10 -36 28
0 20 -52 101 -73 115 -12 8 0 29 16 29 7 0 13 10 13 23 1 12 5 52 9 89 10 90
-7 149 -59 197 -22 20 -58 54 -80 76 -72 72 -102 78 -425 82 -195 3 -287 1
-294 -6 -6 -6 -11 -39 -10 -74 1 -61 1 -62 -8 -19 -6 24 -16 42 -24 42 -7 0
-15 5 -17 12 -3 8 -31 12 -89 11 -83 -1 -132 -15 -150 -45 -7 -10 -9 -10 -9 0
0 6 -9 12 -20 12 -11 0 -20 5 -20 10 0 6 -8 14 -17 17 -10 4 -34 16 -53 27
-19 11 -39 21 -45 23 -5 1 -37 9 -70 17 -75 19 -176 19 -235 0z m90 -139 l0
-85 -81 0 -80 0 18 33 c35 62 107 135 136 137 4 0 7 -38 7 -85z m105 66 c32
-13 78 -65 78 -87 0 -8 3 -14 7 -14 4 0 12 -11 18 -25 l12 -25 -80 0 -80 0 0
86 c0 47 3 84 8 82 4 -3 21 -10 37 -17z m-1177 -68 l3 -72 -28 -3 -28 -3 -3
-241 -2 -242 22 -4 c13 -2 47 -2 76 0 l52 4 0 69 0 69 95 0 95 0 0 -145 c0
-90 -4 -145 -10 -145 -5 0 -129 0 -275 0 -146 0 -285 0 -310 0 l-45 0 0 70 c0
69 0 69 30 77 l31 8 0 233 c-1 127 -1 237 -1 242 0 6 -13 10 -30 10 l-30 0 0
68 c0 38 3 72 7 76 4 3 84 5 177 4 l171 -3 3 -72z m2339 57 c66 -19 119 -71
133 -129 18 -77 5 -135 -42 -184 -36 -38 -38 -43 -21 -52 49 -27 91 -81 101
-131 13 -60 3 -140 -21 -178 -25 -38 -77 -72 -127 -84 -25 -6 -163 -12 -310
-13 l-265 -2 -3 71 -3 72 31 0 30 0 0 249 0 250 -32 3 c-18 2 -32 6 -30 11 1
4 2 37 2 73 l0 66 258 -5 c163 -3 272 -9 299 -17z m968 9 c4 -6 51 -151 104
-322 l97 -312 40 -5 39 -5 3 -67 c2 -38 -1 -68 -5 -68 -4 0 -102 0 -218 -1
-115 0 -216 0 -222 0 -9 1 -13 22 -13 71 l0 70 50 0 c44 0 50 3 50 21 0 11 -5
29 -10 40 -10 17 -22 19 -121 19 l-110 0 -14 -32 c-17 -42 -12 -48 36 -48 l39
0 0 -70 c0 -60 -3 -70 -17 -70 -10 -1 -90 -1 -178 -1 l-160 -1 -3 70 -3 71 37
3 38 3 38 125 c21 69 52 166 67 215 45 140 47 155 22 155 -19 0 -21 6 -21 68
0 38 3 72 7 75 11 12 421 8 428 -4z m-2405 -39 c0 -5 -4 -10 -9 -10 -5 0 -15
-17 -22 -37 -17 -55 -27 -63 -76 -63 -41 0 -44 2 -32 17 18 21 122 103 132
103 4 0 7 -4 7 -10z m426 -5 c4 -8 10 -12 15 -9 11 7 40 -21 31 -30 -3 -4 2
-4 13 0 11 4 17 4 13 0 -3 -4 8 -21 24 -37 l31 -29 -52 0 c-34 0 -51 4 -51 13
0 6 -11 33 -25 60 -14 26 -21 47 -15 47 6 0 13 -7 16 -15z m247 -6 c20 -5 37
-14 37 -19 0 -4 -9 -29 -20 -54 -22 -52 -53 -63 -66 -23 -4 12 -20 37 -35 55
-35 40 -36 46 -4 38 14 -4 25 -2 25 4 0 13 15 13 63 -1z m-1501 -51 c3 -12 3
-30 -1 -105 -1 -16 -8 -23 -28 -25 l-28 -3 0 -177 c0 -97 -3 -179 -7 -183 -4
-4 -14 -5 -23 -2 -13 6 -15 35 -15 207 l0 201 30 10 c26 10 30 16 30 50 0 32
4 39 19 39 10 0 21 -6 23 -12z m3332 -62 c9 -30 16 -58 15 -63 -1 -11 -37 88
-38 105 -2 27 8 9 23 -42z m-868 -41 c18 -44 18 -116 0 -159 -19 -46 -27 -48
-60 -19 -24 21 -28 28 -18 42 30 41 44 76 49 122 3 27 8 49 10 49 2 0 11 -16
19 -35z m-999 -6 c-15 -11 -27 -27 -27 -35 0 -8 -6 -14 -13 -14 -7 0 -32 -18
-56 -40 -23 -22 -44 -40 -45 -40 -2 0 -4 29 -4 64 l-1 64 65 0 c47 0 71 4 82
16 9 8 18 13 20 11 3 -3 -7 -14 -21 -26z m-724 -5 c12 -4 13 -12 6 -37 -5 -18
-10 -56 -11 -85 l-3 -52 -52 0 c-33 0 -53 4 -53 11 0 8 -5 8 -15 -1 -13 -11
-15 -7 -15 27 0 53 28 131 48 136 26 7 79 8 95 1z m257 -87 l0 -88 -100 2
c-55 1 -102 4 -104 8 -4 6 8 96 19 138 5 23 32 28 142 28 l43 0 0 -88z m249
41 c6 -27 11 -57 11 -67 0 -23 30 -36 42 -19 5 9 8 9 8 1 0 -6 -20 -23 -44
-37 -47 -27 -64 -54 -44 -68 19 -12 99 2 130 23 31 23 39 24 32 5 -7 -19 25
-35 57 -29 26 5 27 4 21 -43 -3 -27 -14 -68 -24 -91 -18 -40 -21 -43 -59 -43
-29 0 -38 3 -33 13 6 10 4 10 -7 0 -22 -22 -25 -15 -18 45 11 87 10 99 -7 106
-28 11 -54 -12 -54 -49 0 -18 -4 -36 -10 -40 -5 -3 -7 -12 -4 -20 3 -7 0 -23
-6 -35 -9 -17 -17 -20 -36 -16 -34 9 -62 -8 -60 -36 1 -13 -3 -26 -8 -30 -6
-4 9 -4 32 -1 23 3 42 2 42 -3 0 -33 -90 -137 -127 -149 -23 -6 -23 -5 -23 73
0 73 2 80 22 85 18 5 19 8 8 21 -7 10 -8 16 -2 16 6 0 12 -5 14 -11 2 -5 11
-7 21 -4 9 4 15 11 12 15 -3 5 3 17 14 27 10 10 22 29 26 43 4 14 19 42 33 63
19 29 23 40 13 46 -7 5 -58 8 -114 7 l-102 -1 3 -42 c3 -34 0 -43 -12 -43 -9
0 -16 -4 -16 -10 0 -5 -9 -10 -19 -10 -11 0 -55 -18 -97 -40 -86 -44 -98 -47
-121 -24 -11 11 -30 14 -61 12 l-46 -3 -18 50 c-10 27 -17 67 -16 87 l1 38 65
0 c53 0 63 -3 58 -15 -3 -8 -1 -15 3 -15 5 0 11 -20 13 -45 2 -26 -1 -45 -7
-45 -6 0 -5 -4 1 -8 7 -4 13 -16 15 -26 2 -10 12 -24 22 -30 30 -18 46 13 36
71 -4 27 -8 63 -8 81 l-1 32 180 0 c150 0 185 3 205 16 47 31 32 44 -50 44
l-75 0 0 91 0 90 89 -2 90 -2 10 -49z m2160 -148 c0 -23 -26 42 -33 81 -4 26
-2 23 13 -16 11 -27 20 -57 20 -65z m46 -146 l6 -29 -14 30 c-8 17 -17 44 -20
60 l-6 30 14 -31 c8 -17 17 -44 20 -60z m-2883 -71 c22 -48 35 -63 51 -64 34
-2 -53 -18 -100 -18 -24 -1 -43 4 -43 9 0 6 -7 10 -15 10 -8 0 -15 7 -15 16 0
24 31 85 47 91 7 3 10 9 7 14 -3 5 2 9 10 9 9 0 16 6 17 13 0 7 3 6 6 -3 3 -8
19 -43 35 -77z m1936 48 c36 -118 -19 -238 -125 -272 -25 -8 -133 -14 -315
-16 l-278 -4 0 24 0 25 238 4 c214 4 242 7 292 26 96 38 160 127 160 224 0 40
15 35 28 -11z m-2228 -151 l0 -140 -312 2 c-305 3 -313 3 -313 23 0 19 8 20
279 23 186 1 284 6 293 13 9 8 13 41 13 115 0 97 1 104 20 104 19 0 20 -7 20
-140z m2758 110 c-7 -11 -15 -20 -18 -20 -3 0 -11 9 -18 20 -11 18 -10 20 18
20 28 0 29 -2 18 -20z m-1972 -12 c-10 -6 -20 -7 -24 -3 -9 8 20 25 31 18 5
-3 2 -10 -7 -15z m290 -80 c-12 -13 -40 -36 -62 -52 -21 -17 -42 -35 -46 -40
-4 -6 -10 -7 -14 -3 -4 4 0 15 8 24 9 10 21 35 29 56 13 36 15 37 61 37 l47 0
-23 -22z m1674 -58 c0 -22 5 -40 10 -40 6 0 10 -13 10 -30 l0 -30 -175 0 -175
0 0 25 0 25 139 0 c77 0 146 3 155 6 11 4 16 19 16 45 0 21 5 39 10 39 6 0 10
-18 10 -40z m560 -30 l0 -70 -225 0 -225 0 0 25 0 25 199 0 c218 0 211 -2 211
61 0 22 5 29 20 29 18 0 20 -7 20 -70z"/>
<path d="M5597 4583 c-4 -3 -7 -42 -7 -85 l0 -78 54 0 c65 0 86 21 86 83 0 52
-35 87 -88 87 -21 0 -42 -3 -45 -7z m93 -48 c14 -16 6 -35 -16 -35 -8 0 -14
-10 -14 -25 0 -18 -5 -25 -20 -25 -17 0 -20 7 -20 50 0 48 1 50 29 50 16 0 34
-7 41 -15z"/>
<path d="M5592 4193 l3 -98 44 -3 c88 -6 139 42 126 119 -10 56 -43 79 -117
79 l-59 0 3 -97z m126 45 c23 -23 13 -38 -23 -38 -33 0 -35 -2 -35 -35 0 -28
-4 -35 -20 -35 -18 0 -20 7 -20 60 l0 60 43 0 c24 0 48 -5 55 -12z"/>
<path d="M6572 4426 c-18 -57 -32 -107 -32 -110 0 -3 32 -6 71 -6 53 0 70 3
66 13 -2 6 -17 56 -33 109 -16 54 -32 98 -35 98 -3 0 -20 -47 -37 -104z m54
-54 c6 -27 4 -32 -13 -32 -29 0 -32 7 -20 46 12 39 24 34 33 -14z"/>
<path d="M4200 4390 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4933 4256 c14 -14 21 -16 30 -7 8 8 8 11 0 11 -6 0 -20 3 -30 7 -17
7 -17 6 0 -11z"/>
<path d="M3117 4799 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M2400 4789 c-97 -17 -224 -113 -266 -203 -10 -19 -22 -32 -27 -29 -6
3 -7 1 -3 -5 3 -6 1 -23 -5 -39 -7 -15 -17 -68 -24 -117 -10 -79 -9 -98 8
-173 21 -92 66 -180 142 -281 19 -25 50 -56 67 -69 18 -13 32 -29 31 -34 -2
-5 6 -9 16 -9 11 0 41 -9 67 -20 70 -31 202 -31 268 -2 32 15 46 18 43 9 -6
-16 -15 -15 157 -18 180 -2 176 -5 176 160 0 104 2 118 20 134 17 14 20 29 20
102 0 88 0 89 -54 132 -11 10 -16 10 -19 0 -3 -7 -6 67 -6 164 -1 165 -2 177
-22 195 -11 10 -26 19 -32 20 -7 0 -20 2 -29 2 -11 1 -18 13 -20 35 l-3 32
-95 3 c-109 3 -140 -2 -140 -24 0 -17 -30 -12 -65 12 -11 8 -30 14 -43 14 -13
0 -21 4 -18 8 6 10 -91 10 -144 1z m166 -54 c27 -8 61 -26 76 -40 35 -33 43
-32 54 8 l10 32 84 0 85 0 0 -165 0 -165 -101 -3 -102 -3 -6 35 c-13 69 -87
124 -148 109 -71 -18 -128 -109 -128 -202 0 -79 16 -126 57 -172 33 -37 39
-39 93 -39 71 0 113 27 108 69 -2 21 -9 27 -36 29 l-33 3 3 57 3 57 188 3 187
2 0 -59 c0 -54 -2 -60 -22 -63 -22 -3 -23 -7 -28 -141 l-5 -139 -108 0 c-60 1
-110 2 -111 4 -1 2 -10 15 -19 31 l-18 27 -26 -24 c-56 -53 -184 -64 -287 -26
-155 58 -249 254 -216 450 41 242 239 387 446 325z m382 -222 c-3 -112 -5
-128 -20 -131 -16 -3 -18 8 -18 127 0 125 1 131 20 131 20 0 20 -5 18 -127z
m-351 -23 l25 -19 -38 -16 c-74 -31 -108 -84 -122 -186 -7 -57 -9 -62 -20 -43
-6 12 -14 46 -18 75 -17 143 87 257 173 189z m-32 -301 c21 -8 21 -8 -8 -14
-29 -5 -41 34 -31 102 l9 58 5 -69 c4 -57 8 -70 25 -77z m463 12 c3 -46 1 -53
-17 -58 -20 -5 -21 -13 -22 -129 -1 -68 -2 -130 -3 -136 -2 -19 -216 -24 -228
-6 -20 33 -9 38 80 38 55 0 92 4 100 12 8 8 12 53 12 138 0 114 2 126 20 138
11 7 20 20 20 30 0 10 3 22 7 26 16 16 28 -4 31 -53z m-370 -271 c7 -11 20
-20 30 -20 27 -1 -22 -37 -66 -50 -79 -22 -203 -3 -254 40 l-23 19 40 -11 c56
-15 165 -5 211 19 50 26 47 26 62 3z"/>
<path d="M2650 4790 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6861 4784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2285 4780 c-7 -12 2 -12 30 0 19 8 19 9 -2 9 -12 1 -25 -3 -28 -9z"/>
<path d="M5226 4773 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M7254 4765 c-9 -12 -14 -47 -14 -97 0 -45 -4 -78 -10 -78 -5 0 -10
-8 -10 -17 0 -15 2 -16 10 -3 9 14 14 7 11 -13 -1 -4 12 -10 29 -13 l30 -6 0
-199 c0 -191 -1 -199 -20 -199 -30 0 -40 -31 -40 -122 0 -89 16 -133 45 -122
12 5 15 -2 15 -29 0 -68 -7 -67 375 -67 383 0 374 -2 376 68 0 20 0 98 -1 172
-2 149 -9 170 -62 170 -24 0 -28 4 -28 29 0 17 -5 33 -11 37 -6 3 -60 6 -120
5 l-109 -2 0 78 c0 77 0 78 30 91 l30 14 0 103 c0 116 -6 130 -65 141 -28 5
-35 11 -35 29 0 12 -6 27 -13 33 -8 7 -87 12 -206 14 -183 3 -194 2 -207 -17z
m384 -102 l3 -72 -28 -3 -28 -3 -3 -239 c-2 -164 1 -242 8 -246 6 -4 28 -6 48
-4 20 1 49 3 65 3 27 1 27 1 27 71 l0 71 93 -3 92 -3 3 -142 c1 -79 -1 -143
-5 -143 -22 -1 -610 -1 -620 -1 -21 1 -18 137 3 145 9 3 20 4 24 1 5 -3 11 -1
15 5 5 8 8 244 5 458 0 29 -3 32 -30 32 l-30 0 0 68 c0 38 3 72 7 76 4 3 84 5
177 4 l171 -3 3 -72z m72 -93 c0 -67 -1 -70 -24 -70 -13 0 -27 -7 -30 -16 -3
-9 -6 -92 -6 -185 0 -137 -3 -169 -14 -169 -22 0 -26 34 -26 228 l0 183 30 10
c31 11 33 16 30 62 -1 21 3 27 19 27 19 0 21 -6 21 -70z m280 -570 l0 -140
-316 0 -315 0 3 23 3 22 290 5 c160 3 291 7 293 8 1 1 2 52 2 112 0 103 1 110
20 110 19 0 20 -7 20 -140z"/>
<path d="M7748 4713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7821 4704 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5990 4701 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
<path d="M9048 4683 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7021 4624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6120 4554 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M7281 4494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6145 4490 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7020 4475 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M6281 4364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3095 4310 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6110 4280 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6241 4204 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8237 4149 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M8770 4150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8398 4143 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8489 4133 c-13 -15 -12 -15 9 -4 23 12 28 21 13 21 -5 0 -15 -7 -22
-17z"/>
<path d="M8206 4119 c-23 -27 -15 -35 9 -9 10 11 16 22 14 25 -3 2 -13 -5 -23
-16z"/>
<path d="M8668 4133 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8772 4110 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8702 4098 c7 -15 15 -23 17 -17 2 6 -4 18 -13 27 -15 15 -15 15 -4
-10z"/>
<path d="M9065 4110 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8735 4079 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2111 4064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8170 4069 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8760 4060 c0 -11 2 -20 4 -20 2 0 6 9 9 20 3 11 1 20 -4 20 -5 0 -9
-9 -9 -20z"/>
<path d="M9071 4064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8310 4059 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8345 4060 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M8610 4060 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8901 4054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8267 4053 c-9 -9 -9 -91 1 -96 4 -3 7 19 7 49 0 30 0 54 -1 54 0 0
-4 -3 -7 -7z"/>
<path d="M8530 4044 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M8550 4010 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2170 3971 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M8171 3964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8320 3970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8531 3964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8840 3971 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M8991 3964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8125 3940 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M8170 3930 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8446 3917 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M8421 3904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9081 3904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8832 3890 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M9002 3885 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M8677 3875 c-15 -14 -24 -25 -19 -25 5 0 19 11 32 25 30 32 23 32
-13 0z"/>
<path d="M8170 3883 c0 -13 29 -38 35 -31 3 3 -4 13 -15 23 -11 10 -20 13 -20
8z"/>
<path d="M4320 3860 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8135 3860 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8861 3854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9018 3843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M8323 3813 c136 -2 358 -2 495 0 136 1 24 2 -248 2 -272 0 -384 -1
-247 -2z"/>
<path d="M9088 3813 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M8055 3800 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8318 3793 c133 -2 351 -2 485 0 133 1 24 2 -243 2 -267 0 -376 -1
-242 -2z"/>
<path d="M9061 3796 c12 -10 59 -15 59 -6 0 5 -14 10 -32 10 -17 0 -29 -2 -27
-4z"/>
<path d="M2410 3780 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M6668 3783 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8008 3783 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7168 3773 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2478 3763 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M8318 3733 c133 -2 351 -2 485 0 133 1 24 2 -243 2 -267 0 -376 -1
-242 -2z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
